import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Button, Error, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from '../common';
import { FigmaEmbed } from '../common/FigmaEmbed';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "error"
    }}>{`Error`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Error } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=5564%3A14026'} storybook={'/?path=/story/error-error--playground'} vue={'/components/HseError/HseError.html'} vueStorybook={'/?path=/story/error-error--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Error – это компонент ошибки. Используется для оповещения пользователя об ошибке. Может выводиться в произвольном месте.`}</p>
    <p>{`Текст ошибки состоит из двух частей — краткое описание (проп `}<inlineCode parentName="p">{`title`}</inlineCode>{`) и более полное (проп `}<inlineCode parentName="p">{`description`}</inlineCode>{`).`}</p>
    <p>{`Опционально можно добавить кнопку с необходимым действием: вы можете передать проп `}<inlineCode parentName="p">{`buttonText`}</inlineCode>{` и добавить обработчик события `}<inlineCode parentName="p">{`onButtonClick`}</inlineCode>{`, либо заполнить слот `}<inlineCode parentName="p">{`action`}</inlineCode></p>
    <Playground __position={1} __code={'<div\n  style={{\n    display: \'flex\',\n    alignItems: \'center\',\n    justifyContent: \'center\',\n    height: \'250px\',\n  }}\n>\n  <Error\n    title=\"Ошибка сети\"\n    description=\"Проверьте свой доступ к интернету\"\n    buttonText=\"Обновить\"\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Button,
      Error,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '250px'
      }}>
    <Error title="Ошибка сети" description="Проверьте свой доступ к интернету" buttonText="Обновить" mdxType="Error" />
      </div>
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node={'?node-id=8323%3A33919'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node={'?node-id=9336%3A35547'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "кастомизация-кнопок-действия"
    }}>{`Кастомизация кнопок действия`}</h3>
    <p>{`Вы можете заменить слот с кнопкой своим компонентом, передав его в проп `}<inlineCode parentName="p">{`action`}</inlineCode></p>
    <Playground __position={4} __code={'<div\n  style={{\n    display: \'flex\',\n    alignItems: \'center\',\n    justifyContent: \'center\',\n    height: \'250px\',\n  }}\n>\n  <Error\n    title=\"Ошибка доступа\"\n    description=\"У вас нет доступа к данному документу\"\n    action={\n      <span style={{ display: \'flex\' }}>\n        <Button variant=\"secondary\">Вернуться назад</Button>\n        <Space horizontal size=\"small_2x\" />\n        <Button variant=\"primary\">Запросить доступ</Button>\n      </span>\n    }\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Button,
      Error,
      Space,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '250px'
      }}>
    <Error title="Ошибка доступа" description="У вас нет доступа к данному документу" action={<span style={{
          display: 'flex'
        }}>
            <Button variant="secondary" mdxType="Button">
                Вернуться назад
            </Button>
            <Space horizontal size="small_2x" mdxType="Space" />
            <Button variant="primary" mdxType="Button">
                Запросить доступ
            </Button>
        </span>} mdxType="Error" />
  </div>
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Error} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой элемент `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      